import './components/header.component';
import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';

import './components/swiper-about-jobs.component';
import './components/swiper-vacancy-jobs.component';
// import './components/accordion-vacancies.component';
import './components/swiper-our-values.component';
import './components/swiper-follow-us.component';
import './components/swiper-register.component';
import './components/swiper-testimonials.component';
import './components/swiper-contact.component';
import './components/swiper-team.component.js';

import './libs/countUp.lib.js';

/* Enfore number pattern for forms */

document.querySelectorAll('input[type="tel"]').forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
    if (this.value.length > 1 && this.value.indexOf('+') !== 0) {
      this.value = this.value.replace('+', '');
    }
  });
});

/* CARDS HOVER */
window.handleTilt = {
  init: function () {
    const filteredCards = Array.from(
      document.querySelectorAll('.about-jobs-card')
    ).filter((card) => {
      return !card.closest('.vacancy-jobs-swiper-wr');
    });

    VanillaTilt.init(filteredCards, {
      max: 10,
      speed: 500,
      scale: 1.05,
      transition: true,
    });
  },
  destroy: function () {
    const filteredCards = Array.from(
      document.querySelectorAll('.about-jobs-card')
    ).filter((card) => {
      return !card.closest('.vacancy-jobs-swiper-wr');
    });

    filteredCards.forEach((card) => {
      card.vanillaTilt && card.vanillaTilt.destroy();
    });
  },
};

handleTilt.init();

/* HANDLE HOME PAGE VIDEOS */
const video1 = document.getElementById('video1');
const video2 = document.getElementById('video2');
const videoContainer1 = document.getElementById('videoContainer1');
const videoContainer2 = document.getElementById('videoContainer2');

let isVideo1Playing = true;

video1.play();

function switchVideos() {
  if (isVideo1Playing) {
    video1.pause();
    video1.currentTime = 0;
    videoContainer1.style.opacity = '0';
    videoContainer2.style.opacity = '1';
    video2.play();
  } else {
    video2.pause();
    video2.currentTime = 0;
    videoContainer1.style.opacity = '1';
    videoContainer2.style.opacity = '0';
    video1.play();
  }
  isVideo1Playing = !isVideo1Playing;
}

video1.addEventListener('ended', switchVideos);
video2.addEventListener('ended', switchVideos);
