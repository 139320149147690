window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

function formatInput(inputEl, maxValue) {
  let sanitizedValue = inputEl.value.replace(/[^\d]/g, '');

  if (sanitizedValue === '') {
    inputEl.value = '';
    return;
  }

  const numericValue = Number.isNaN(parseInt(sanitizedValue))
      ? 0
      : parseInt(sanitizedValue);

  sanitizedValue = Math.min(numericValue, maxValue).toString();
  const formattedValue = sanitizedValue.replace(
      /(\d)(?=(\d{3})+(\D|$))/g,
      '$1 '
  );
  inputEl.value = '£ ' + formattedValue;
}

if (document.querySelector('.salary-input')) {
  const salaryInputsArr = document.querySelectorAll('.salary-input');
  salaryInputsArr.forEach((input) => {
    formatInput(input, 99999);
    input.addEventListener('input', () => {
      formatInput(input, 99999);
    });
  });
}

