import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let ourValuesSwiperWr = document.querySelectorAll('.our-values-swiper-wr');
ourValuesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, 
      observeParents: true, 
      slidesPerView: 1.05,
      spaceBetween: 16,
      breakpoints: {
        551: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2.05,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        }
      },  
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
    });

    swiperObserver(swiper);
  }
});
